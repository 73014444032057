import React from 'react';
import PropTypes from 'prop-types';

import Type from 'components/type';

import { Container, StyledInput, StyledLabel } from './radio.css';

const Radio = ({
  htmlFor,
  name,
  text,
  value,
  id,
  setFieldValue,
  checkAgainst,
}) => (
  <Container className="styled-radio font-malee">
    <StyledInput
      type="radio"
      name={name}
      value={value}
      id={id}
      checked={checkAgainst === value}
      onChange={() => setFieldValue(name, value)}
    />
    <StyledLabel htmlFor={htmlFor}>
      <Type size="small-serif">{text}</Type>
    </StyledLabel>
  </Container>
);

Radio.propTypes = {
  htmlFor: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
};

export default Radio;
