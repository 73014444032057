import React from 'react';
import PropTypes from 'prop-types';

class ChangeStep extends React.PureComponent {
  componentDidMount() {
    const {changeApplicationStep, section} = this.props
    changeApplicationStep(section)
  }
  render() {
    return null;
  }
}

ChangeStep.propTypes = {};

export default ChangeStep;
