import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import Results from 'components/results';

const ResultsContainer = ({
  children,
  validateForm,
  setFieldTouched,
  isValid,
}) => (
  <Consumer>
    {({ openResults, showResults, hideResults }) => (
      <Results
        openResults={openResults}
        showResults={showResults}
        hideResults={hideResults}
        validateForm={validateForm}
        setFieldTouched={setFieldTouched}
        isValid={isValid}
      >
        {children}
      </Results>
    )}
  </Consumer>
);

ResultsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  validateForm: PropTypes.func,
  setFieldTouched: PropTypes.func,
  scrollTo: PropTypes.func,
};

export default ResultsContainer;
