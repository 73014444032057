import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import slugify from 'slugify';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import PageWrapper from 'components/page-wrapper';
import Box from 'components/box';
import Divider from 'components/divider';
import Type from 'components/type';
import Head from 'components/head';
import AppForm from 'components/app-form';

const ApplicationForm = ({ data }) => (
  <PageWrapper bg="bg-info select-infoBright">
    <Head pageTitle="Application" />
    <Box
      width="max-w-full pt-9 sm:pt-30 px-9 sm:px-14"
      flex="flex flex-col items-center"
    >
      <Box width="pt-14 px-6 md:px-16 max-w-xl xl:max-w-2xl">
        <Type align="text-center uppercase" size="large">
          Start Application Process
        </Type>
        <Divider spacing="my-14" />
      </Box>
      <Box width="px-6 md:px-16 pb-14 max-w-xl xl:max-w-2xl">
        <AppForm />
      </Box>
    </Box>
  </PageWrapper>
);

ApplicationForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ApplicationForm;

export const query = graphql`
  query ApplicationFormQuery {
    contentfulApplicationPage {
      id
      headline {
        id
        json
      }
      deadline
      requirements
    }
  }
`;
