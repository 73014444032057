import React from 'react';

import IO from 'components/io';
import Title from 'components/title';
import ChangeStep from 'containers/change-step'

const IOChangeStep = ({ section }) => (
  <IO rootMargin="-200px">
    {({ isVisible }) => (
      isVisible && <ChangeStep section={section} />
    )}
  </IO>
);

export default IOChangeStep;
