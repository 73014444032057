import nanostyled from 'nanostyled';

export const Container = nanostyled('div', {
  base: 'w-full mb-4 relative',
});

export const StyledLabel = nanostyled('label', {
  base:
    'flex border border-black border-solid bg-infoBright hover:bg-info uppercase w-full  p-4',
});

export const StyledInput = nanostyled('input', {
  base: 'appearance-none absolute right-0 border-0',
});
