import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';

import ChangeStep from 'components/change-step'

const ChangeStepContainer = ({ section}) => (
  <Consumer>{({ changeApplicationStep }) => 
      <ChangeStep changeApplicationStep={changeApplicationStep} section={section}  />
  }</Consumer>
);

ChangeStepContainer.propTypes = {
  section: PropTypes.string,
};

export default ChangeStepContainer;
