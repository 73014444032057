import styled from 'styled-components';
import nanostyled from 'nanostyled'

export const Button = styled.div`
  font-size: 1em;
  display: inline-block;
  margin: 0 auto 4rem;
  border: none;
  outline: none;
  cursor: pointer;
  color: black;
  padding: 1em;
  border-radius: 2px;
  text-decoration: none;
  transition: 0.2s;
  z-index: 99;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  font-weight: normal;
  padding: 1rem;
  line-height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
`;

export const Submit = nanostyled('button', {
  base: 'w-full cursor-pointer uppercase hover:bg-info border-solid border border-black bg-infoBright p-4 font-malee text-base',
});
